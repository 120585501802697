<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <h1 v-if="patient">Add a Consent to client {{ patient.name }}</h1>
      </div>
    </div>
    <div class="card theme-card mx-auto col-12 col-md-8 shadow-card">
      <div class="card-body md:px-4 px-2">
        <alert v-if="consentsLoading" class="mb-0" />
        <alert v-else-if="consents.length == 0" :hideLoader="true" class="my-4">
          No Consents Available to Attach
        </alert>
        <form v-else @submit.prevent="attachConsent" validate>
          <div class="form-group">
            <label>Consent</label>
            <select
              class="form-control"
              required
              v-model="dataToSubmit.consent_id"
            >
              <option v-for="item in consents" :key="item.id" :value="item.id">
                {{ item.title }}
              </option>
            </select>
          </div>

          <div class="d-flex flex-column">
            <label>Attachments</label>
            <dropzone
              ref="dropzone"
              :options="dropzoneOptions"
              @success="onUploadSuccess"
            />
            <div v-if="filesToDisplay.length > 0" class="mt-2">
              <FileInputTable :files="filesToDisplay" @remove="removeFile" />
            </div>
          </div>

          <save type="submit" :saving="attachConsentLoading">
            Attach Consent
          </save>
          <button
            v-if="src"
            type="button"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      dataToSubmit: {
        consent_id: null,
        attachments: [],
      },
    };
  },
  mounted() {
    if (this.patientId) {
      this.getConsents();
    } else {
      this.navigateBack();
    }
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      consents: (state) => state.consents.consents.data,
      consentsLoading: (state) => state.consents.consents.loading,
      attachConsentLoading: (state) => state.consents.attachUserConsentLoading,
    }),
    patientId() {
      if (this.patient?.user_id) {
        return this.patient.user_id;
      }
      return null;
    },
    allowedExtensions: function () {
      return [
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".jpg",
        ".jpeg",
        ".png",
      ];
    },
    dropzoneOptions: function () {
      return {
        url: `users/${this.patientId}/consent-attachment`,
        maxFiles: 5,
        maxFilesize: 50,
        acceptedFiles: this.allowedExtensions.join(","),
        noFinalAlert: true,
      };
    },
    src: function () {
      return this.$route.query.src;
    },
    filesToDisplay: function () {
      return this.dataToSubmit.attachments.map((file) => {
        return {
          id: file.id,
          dropzoneUuid: file.dropzoneUuid,
          name: file.original_file_name,
          size: file.readable_size,
          mimeType: file.mime_type,
          extension: file.extension,
          createdAt: file.created_at,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      getConsents: "consents/getConsents",
      attachConsentToUser: "consents/attachConsentToUser",
    }),
    attachConsent: async function () {
      const payload = {
        ...this.dataToSubmit,
        user_id: this.patient.user_id,
      };
      const success = await this.attachConsentToUser(payload);
      if (success) {
        this.navigateBack();
      }
    },
    onUploadSuccess: function (file) {
      const fileToAdd = file.xhr.response
        ? JSON.parse(file.xhr.response)
        : null;
      if (fileToAdd) {
        this.dataToSubmit.attachments.push({
          dropzoneUuid: file.upload.uuid,
          ...fileToAdd,
        });
        this.$forceUpdate();
      }
    },
    removeFile: function (file) {
      this.dataToSubmit.attachments = this.dataToSubmit.attachments.filter(
        (item) => item.id !== file.id
      );
      if (this.$refs.dropzone) {
        this.$refs.dropzone.removeFileByUuid(file.dropzoneUuid);
      }
      this.$forceUpdate();
    },
    navigateBack: function () {
      this.$router.back();
    },
  },
};
</script>
